import React, { useEffect, useState } from 'react'
import icon from "../assets/img/icon.svg"
import toast from 'react-hot-toast'
import _fetch from '../Config/api'
import { base_url } from '../Config/config'
import moment from 'moment';
import trading from '../assets/img/trading.png'
import { NavLink } from 'react-router-dom'
import businessman from '../assets/img/businessman.png'
import stockmarket from '../assets/img/stockmarket.jpg'
import tradevideo from '../assets/img/tradevideo.mp4'
import profit from '../assets/img/profit.png'
import bonus from '../assets/img/bonus.png'
import club from '../assets/img/club.png'
import stream from '../assets/img/stream.png'
import loyalty from '../assets/img/loyalty.png'
import partners from '../assets/img/partners.png'
import withdrawal from '../assets/img/withdrawal.png'
import wallet from '../assets/img/wallet.png'
import fund from '../assets/img/fund.png'
import deposit from '../assets/img/deposit.png'
import mobile from '../assets/img/mobile.png'
import transaction from '../assets/img/transaction.png'
import transfer from '../assets/img/transfer.png'
import chart from '../assets/img/chart.png'
import { FaLongArrowAltUp } from "react-icons/fa";
import { FaLongArrowAltDown } from "react-icons/fa";
import Market from '../trader/Market'
import dateFormat from "dateformat";
// import ModalPop from './Success'
const Dashboard = () => {
  const [data, setData] = useState({});
  const [banner, setBanner] = useState('');
  const [name, setName] = useState({});
  const [count, setCount] = useState('')
  const [binanceBalance, setBinanceBalance] = useState(0)
  const [paidStatus, setPaidStatus] = useState('')
  const [packageamount, setPackageamount] = useState('')
  const [packageValiditys, setPackageValiditys] = useState('')
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSer, setpageSer] = useState(0);
  // const [showModal, setShowModal] = useState(false);
  const [packageValidity, setPackageValidity] = useState('')
  const handledashboard = async () => {
    try {
      let response = await _fetch(`${base_url}api/user/dashboard`, 'get', {});
      console.log('resp is ', response)
      if (response.success == true) {
        setData(response?.data?.balance);
        setName(response?.data.user);
        setCount(response?.data)
        setBinanceBalance(parseFloat(response?.data?.binanceBalance))
        setPaidStatus(response?.data?.user.paidStatus)
        setPackageamount(response?.data?.user?.packageAmount)
        setPackageValiditys(response?.data?.user?.packageValidity)
      } else {
        toast.dismiss()
        toast.error(response.message);
      }
    } catch (error) {
      console.log("Error details", error);
    }
  }

  const handleactivite = async () => {
    try {
      let response = await _fetch(`${base_url}api/user/packages`, 'get', {});
      console.log('resp is ', response)
      if (response.success == true) {
        setPackageValidity(response?.data)
      } else {
        toast.dismiss()
        toast.error(response.message);
      }
    } catch (error) {
      console.log("Error details", error);
    }
  }
  const handleactiviteuser = async () => {
    try {
      let response = await _fetch(`${base_url}api/user/activateAccount`, 'get', {});
      console.log('resp is ', response)
      if (response.success == true) {
        toast.dismiss()
        // setPackageValidity(response?.data)
        toast.success(response.message)
        handledashboard()
      } else {
        toast.dismiss()
        toast.error(response.message);
      }
    } catch (error) {
      console.log("Error details", error);
    }
  }


  // getBanners 

  const handlebanner = async (page = 1, pageSize = 10) => {
    try {
      const skip = (page - 1) * pageSize;
      setpageSer(skip);
      const requestData = {
        limit: pageSize,
        skip: skip,

      };

      let response = await _fetch(`${base_url}api/user/getBanners`, 'post', requestData);

      if (response.success === true) {
        setBanner(response);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.log("Error details", error);
    }
  }
  const handlePaginationChange = (pageOne) => {
    setCurrentPage(pageOne);

  };

  useEffect(() => {
    handledashboard();
    handleactivite();
    handlebanner(currentPage, 10);
    // const hasSeenModal = localStorage.getItem('hasSeenModal'); // Check if modal has already been shown

    //     if (!hasSeenModal) {
    //         setShowModal(true);
    //         localStorage.setItem('hasSeenModal', 'true');
    //     }
  }, [currentPage]);



  console.log('banner-=-=-=-=-=-', banner);

  const recordsbanner = () => {
    if (banner && banner.data && banner.data.length > 0) {
      return (
        <div className="container mt-1 p-0">
          {/* <div id="carouselExampleCaptions" className="carousel slide phonepe-carousel" data-bs-ride="carousel" data-bs-interval="3000">
            <div className="carousel-indicators">
              {banner.data.map((_, i) => (
                <button
                  key={i}
                  type="button"
                  data-bs-target="#carouselExampleCaptions"
                  data-bs-slide-to={i}
                  className={i === 0 ? 'active' : ''}
                  aria-label={`Slide ${i + 1}`}
                  aria-current={i === 0 ? 'true' : 'false'}
                ></button>
              ))}
            </div>
            <div className="carousel-inner">
              {banner.data.map((row, i) => (
                <div className={`carousel-item ${i === 0 ? 'active' : ''}`} key={i}>
                  <img className="image_border" src={row.image} alt="..." />
                  <div className="carousel-caption d-md-block">
                   
                  </div>
                </div>
              ))}
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div> */}
          <video className='' width="600" height="240" autoPlay loop muted>
            <source src={tradevideo} type="video/ogg" />
          </video>
        </div>

      );
    } else {
      return (
        <div>
          <p className='text-center text-light mt-5'>Data Not Found</p>
        </div>
      );
    }
  };
  // const handleClose = () => setShowModal(false);
  return (
    <>
      {/* <div className=''>
                <ModalPop open={showModal} handleClose={handleClose} />
            </div> */}

      <div className='main-trade'>
        <div className="container mt-5 p-1">
          {/* {recordsbanner()} */}
          <video className='' width="600" height="240" autoPlay loop muted>
            <source src={tradevideo} type="video/ogg" />
          </video>
        </div>
        <div className="container mt-2">
          <h3 className='marque'><marquee> Congratulations! The Trades Goal Portal has launched on the special occasion of Dusshera. </marquee></h3>

          {/* <div class="true-false m-auto">
          <div className='true-fals-2'>
            <div className='click-me m-auto d-block'> */}


          {/* {!paidStatus && (
<>
                <button type="submit" className="button">Click me!</button>
                <p> packageAmount:-{packageValidity?.packageAmount}</p>
                <p> packageAmount:-{packageValidity?.packageValidity} d</p>
                </>

              )} */}
          {!paidStatus && (
            <>
              <div className='row mt-3'>
                <div className='col-sm-4 p-1'>
                  <button type="submit" className="custom-btn btn-3 d-flex m-auto" onClick={handleactiviteuser}>Activate Account </button>

                </div>
                <div className='col-sm-4 p-1'>
                  <p className='custom-btn btn-5 d-flex m-auto '> Bot Activation Fees: ${packageValidity?.packageAmount}</p>

                </div>
                <div className='col-sm-4 p-1'>
                  <p className='custom-btn btn-5 d-flex m-auto '> Bot Validity:-{packageValidity?.packageValidity} days</p>

                </div>
              </div>

            </>

          )}
          {/* </div>


          </div>
        </div> */}
        </div>
        <div className="container mt-2 p-1">
          <div className="zoom_box w-100">
            <div className="row ">

              <div className="col-6 p-1 text-center">
                <h4 className="remaining m-auto">
                  <span className="main_zoom">Registered At</span>
                  <div className="today_text text-light">{dateFormat(name?.createdAt, "yyyy-mm-dd, hh:mm:ss")}</div>

                </h4>
              </div>
              <div className="col-6 p-1 text-center">
                <h4 className="remaining m-auto">
                  <span className="main_zoom">Bot Activation Date </span>
                  <div className="today_text text-light">{dateFormat(name?.updatedAt, "yyyy-mm-dd")}</div>

                </h4>
              </div>{" "}

            </div>
          </div>
        </div>

        <div className="container mt-2 p-1">
          <div className="section-title">
            <div className="">
              <div className="top">
                <h2 className="my">Personal Details</h2>
              </div>
              <div className="col-xl-12">
                <div className="stats">
                  <ul className='p-0'>
                    <li className="stats_li br-right btm-br">
                      <span className="reward">
                        <img
                          src={profit}
                          className="img-fluid"
                        />
                      </span>
                      <span className="coin text-light">${count?.last24hourProfit?.toFixed(2)}</span>
                      <p className="bonus">Daily Trade Profit</p>
                    </li>
                    <li className="stats_li br-right btm-br">
                      <span className="reward">
                        <img
                          src={bonus}
                          className="img-fluid"
                        />
                      </span>
                      <span className="coin text-light">${count?.directTeamBonus?.toFixed(2)}</span>
                      <p className="bonus">Direct Team Bonus</p>
                    </li>
                    <li className="stats_li btm-br">
                      <span className="reward">
                        <img
                          src={club}
                          className="img-fluid"
                        />
                      </span>
                      <span className="coin text-light">${count?.clubBonus?.toFixed(2)}</span>
                      <p className="bonus">Club Bonus</p>
                    </li>
                  </ul>
                  <ul className="p-0">
                    <li className="stats_li br-right">
                      <span className="reward">
                        <img
                          src={loyalty}
                          className="img-fluid"
                        />
                      </span>
                      <span className="coin">${count?.loyalityBonus?.toFixed(2)}</span>
                      <p className="bonus">Loyalty Bonus</p>
                    </li>
                    <li className="stats_li br-right">
                      <span className="reward">
                        <img
                          src={stream}
                          className="img-fluid"
                        />
                      </span>
                      <span className="coin">${count?.mainBalance?.toFixed(2)}</span>
                      <p className="bonus">Fuel Wallet</p>
                    </li>
                    <li className="stats_li">
                      <span className="reward">
                        <img
                          src={partners}
                          className="img-fluid"
                        />
                      </span>
                      <span className="coin">{count?.teamCount || "N/A"}</span>
                      <p className="bonus"> Total Team</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="container mt-2 p-0">
          <div className='main-boz p-1'>
            <div className='row text-h2 m-auto'>

              <div className='col-lg-4 col-md-4 col-sm-12 col-12 p-1'>
                <div className='box_card1'>
                  <div className='hii2'>
                    <h6>Bot Activation Fees <b>  ${packageamount}</b></h6>
                    <h6>Bot Validity<b>  {packageValiditys}</b></h6>
                    <h6>Direct Referral Team <b>  {count?.directsCount || "N/A"}</b></h6>

                  </div>

                </div>
              </div>
              <div className='col-lg-4 col-md-4 col-sm-12 col-12 p-1'>
                <div className='hii'>
                  <div className='box_card-2'>
                    <h6>Exchange Balance <b>  ${binanceBalance.toFixed(2)}</b></h6>
                    <h6 className='' >Working Bonus Wallet  <b> ${count?.incomeBalance?.toFixed(2)}</b></h6>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-4 col-sm-12 col-12 p-1'>
                <div className='box_card2'>
                  <div className='hii'>
                    <h6 className='' >Direct Team Business  <b> ${count?.directsActive?.toFixed(2)} </b></h6>
                    <h6 className='' >Total Team Business  <b>${count?.teamActive?.toFixed(2)} </b></h6>
                    <h6 className='' >Total Team Bonus  <b> 0 </b></h6>
                  </div>

                </div>
              </div>

            </div>
          </div>
        </div>


        <div class="container mt-3 p-1">
          <div class="">
            <div class="col-xl-12">
              <div class="main-btn p-0">
                {/* <h2 class="Money-transfer">Transfer Money</h2> */}
                <ul>
                  <li class="btnn_blue">
                    <div class="image_border d-flex justify-content-center text-center p-0">
                      <NavLink to="/tranctattion" className="inner-btn">
                        <img className="to-mobile" src={transaction} alt="" />
                      </NavLink>
                    </div>
                    <NavLink to="/tranctattion" className="inner-btn">
                      <span className="text-light text-center">Transaction</span>
                    </NavLink>
                  </li>
                  <li class="btnn_blue">
                    <div class="image_border d-flex justify-content-center text-center p-0">
                      <NavLink to="/activity" className="inner-btn">
                        <img src={mobile} alt="" className="img-fluid" />
                      </NavLink>
                    </div>
                    <NavLink to="/activity" className="inner-btn">
                      <span className="text-light text-center">Activites</span>
                    </NavLink>
                  </li>
                  <li class="btnn_blue">
                    <div class="image_border d-flex justify-content-center text-center p-0">
                      <NavLink to="/deposit" class="inner-btn">

                        <img src={fund} alt="" class="img-fluid" />
                      </NavLink>
                    </div>
                    <NavLink to="/deposit" class="inner-btn">
                      <span class="text-light text-center">Fund Deposit</span>
                    </NavLink>
                  </li>
                  <li class="btnn_blue">
                    <div class="image_border d-flex justify-content-center text-center p-0">
                      <NavLink to="/DashBonus" class="inner-btn">
                        <img src={bonus} alt="" class="img-fluid" />
                      </NavLink>
                    </div>
                    <NavLink to="/open_position" class="inner-btn">
                      <span class="text-light text-center">Bonus</span>
                    </NavLink>
                  </li>
                </ul>
                <ul className='mt-2'>
                  <li class="btnn_blue">
                    <div class="image_border d-flex justify-content-center text-center p-0">
                      <NavLink to="/close_position" className="inner-btn">
                        <img className="to-mobile" src={chart} alt="" />
                      </NavLink>
                    </div>
                    <NavLink to="/close_position" className="inner-btn">
                      <span className="text-light text-center">position</span>
                    </NavLink>
                  </li>
                  <li class="btnn_blue">
                    <div class="image_border d-flex justify-content-center text-center p-0">
                      <NavLink to='/fundtransfer' class="inner-btn">
                        <img src={transfer} alt="" class="img-fluid" />
                      </NavLink>
                    </div>
                    <NavLink to="/fundtransfer" class="inner-btn">
                      <span class="text-light text-center">Fund Transfer</span>
                    </NavLink>
                  </li>
                  <li class="btnn_blue">
                    <div class="image_border d-flex justify-content-center text-center p-0">
                      <NavLink to="/wallet" className="inner-btn">
                        <img src={wallet} alt="" class="img-fluid" />
                      </NavLink>
                    </div>
                    <NavLink to="/wallet" className="inner-btn">
                      <span className="text-light text-center">Wallet</span>
                    </NavLink>
                  </li>
                  <li class="btnn_blue">
                    <div class="image_border d-flex justify-content-center text-center p-0">
                      <NavLink to="/mithdraw" class="inner-btn">
                        <img src={withdrawal} alt="" class="img-fluid" />
                      </NavLink>
                    </div>
                    <NavLink to="/mithdraw" class="inner-btn">
                      <span class="text-light text-center">Withdraw</span>
                    </NavLink>
                  </li>
                </ul>

              </div>
            </div>
          </div>
        </div>
        <Market />
      </div>


    </>
  )
}




export default Dashboard;
